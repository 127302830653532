body {
  font-family: Arial, sans-serif;
}

h1 {
  color: #333;
  text-align: center;
  text-transform: uppercase;
  text-decoration: underline;
}

.account {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2em 0;
}

input[type='number'] {
  padding: 0.5em;
  margin: 1em 0;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1em solid #333;
}

button {
  background-color: #bb0707;
  border: 1em;
  border-radius: 5px;
  color: white;
  padding: 1em 2em;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 1em 2px;
  cursor: pointer;
}

h3 {
  color: #555;
}

p {
  text-align: center;
}
